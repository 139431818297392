import React from "react";
import "./Checkbox.css";
const Checkbox = ({ ...props }) => {
  return (
    <>
      <label  className="cyberpunk-checkbox-label">
        <input {...props} type="checkbox" className="cyberpunk-checkbox" />
       {props.label}
      </label>
    </>
  );
};

export default Checkbox;
