export const NavLinks = [
  {
    title: "Ana Sayfa",
    path: "https://www.leagleapp.com",
  },
  {
    title: "Hakkımızda",
    path: "https://www.leagleapp.com/about",
  },
  {
    title: "Profilim",
    path: "/profile",
  },
  {
    title: "Doküman Ara",
    path: "/dokuman-arama-page",
  },
  
  {
    title: "Araştır",
    path: "/yapay-zeka-ile-arama",
  },
  /*{
    title: "Asistan",
    path: "/asistan",
  },*/
  {
    title: "Günce",
    path: "/mevzuat",
  },
];
