import axiosClient from "../client/LeagleClient";
import firebaseClient from "../client/FirebaseClient";

const chatService = {

    async chatWithDocument(selectedDocument, userPrompt, sessionID) {
        try {
            const response = await axiosClient.post('/chat/chit-chat/', {
                ictihat_body: selectedDocument.ictihat_body || selectedDocument.mevzuat_body || selectedDocument.document_body,
                prompt: userPrompt,
            });
            await firebaseClient.saveDocumentTalkToFirestore(userPrompt, response.data.response, sessionID);
            return response.data.response;
        } catch (error) {
            console.error('Error in chatWithDocument:', error);
            throw error;
        }
    },

    async summarizeDocument(selectedDocument, sessionID) {
        const userPrompt = 'özetle'
        try {
            const response = await axiosClient.post('/chat/summarize/', {
                ictihat_body: selectedDocument.ictihat_body || selectedDocument.mevzuat_body || selectedDocument.document_body,
            });

            await firebaseClient.saveDocumentTalkToFirestore(userPrompt, response.data.response, sessionID);
            return response.data.response;
        } catch (error) {
            console.error('Error in summarizeDocument:', error);
            throw error;
        }
    },

    async assistant(query, isNewThread, currentThreadId, documentTypes) {
        try {
            const response = await axiosClient.post('/chat/assistant/', {
                prompt: query, isNewThread: isNewThread, threadId: currentThreadId, documentTypes: documentTypes
            })
            const newResponse = {speaker: 'ai', message: response.data.response};
            await firebaseClient.saveConversationToFirestore(query, newResponse, response.data.reference_documents, response.data.thread_id);
            return response
        } catch (error) {
            console.error('Error in assistant:', error);
            throw error;
        }
    }
};

export default chatService;
