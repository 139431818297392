import { CButton, CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from "@coreui/react";
import { formatDate, parseIctihatBody } from "../utils/DocumentSearchUtils";
import React, { useCallback, useEffect, useState } from "react";
import { auth, db } from "../firebaseConfig";
import { getDocs, collection } from "firebase/firestore";
import {PropagateLoader} from "react-spinners";

const Favorites = () => {
    const [favorites, setFavorites] = useState([]);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [activeButton, setActiveButton] = useState('yargitay');
    const [isLoading, setIsLoading] = useState(true);

    function displayPropagateLoader() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <PropagateLoader />
            </div>
        );
    }

    const DocumentModal = () => {
        if (!showDocumentModal || !selectedDocument) return null;

        return (
            <div className="modal-container">
                <div className="modal-overlay" onClick={() => setShowDocumentModal(false)}></div>
                <div className="modal-content">
                    <button className="close-button" onClick={() => setShowDocumentModal(false)}>×</button>
                    <p>{parseIctihatBody(selectedDocument.ictihat_body)}</p>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchFavoritesFromFirestore = async () => {
            setIsLoading(true)
            const user = auth.currentUser;
            if (!user) {
                return;
            }

            const userId = user.uid;
            const favoritesRef = collection(db, `users/${userId}/favorites`);
            const querySnapshot = await getDocs(favoritesRef);

            const favoritesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFavorites(favoritesList);
            setIsLoading(false)
        };

        fetchFavoritesFromFirestore();
    }, []);

    const handleDocumentClick = useCallback((document) => {
        setSelectedDocument(document);
        setShowDocumentModal(true);
    }, []);

    const changeDocumentTab = (documentType) => {
        setActiveButton(documentType);
    };

    const sendUserToGOV = (link) => {
        window.open(link, '_blank');
    };

    const filterFavorites = () => {
        return favorites.filter(result => {
            if (activeButton === 'mevzuat') {
                return result.mevzuat_type;
            } else if (activeButton === 'yargitay') {
                return result.karar_yeri === 'Yargıtay';
            } else if (activeButton === 'danistay') {
                return result.karar_yeri === 'Danıştay';
            }
            return false;
        });
    };

    const renderSearchResults = () => {
        const filteredFavorites = filterFavorites();

        return (
            <CCol md="lg" style={{ justifyContent: 'center', display: 'flex', maxWidth: '80%', marginBottom: '10px' }}>
                <CContainer style={{ overflowY: 'auto' }}>
                    {filteredFavorites.map((result, index) => (
                        <CCard onClick={() => result.link ? sendUserToGOV(result.link) : handleDocumentClick(result)} className="mb-3" key={index}>
                            <CCardHeader as="h3" style={{ padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                                {result.mevzuat_type ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div>
                                            <h3 style={{ margin: '0', fontSize: '14px', fontWeight: '600' }}>{result.mevAdi}</h3>
                                            <h4 style={{ margin: '0', color: '#555' }}>Mevzuat No: {result.mevzuatNo}</h4>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <div style={{ fontSize: '0.85em', color: '#888' }}>{result.mevzuat_type_name}</div>
                                            <div style={{ fontSize: '0.85em', color: '#888' }}>{result.resmiGazeteTarihi}</div>
                                        </div>

                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                        <div>
                                            <h3 style={{ margin: '0', fontWeight: '600' }}>Karar No: {result.karar_no}</h3>
                                            <h4 style={{ margin: '0', color: '#555' }}>Esas No: {result.esas_no}</h4>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <div style={{ fontSize: '0.85em', color: '#888' }}>{result.karar_yeri}, {result.daire}</div>
                                            <div style={{ fontSize: '0.85em', color: '#888' }}>{formatDate(result.date)}</div>
                                        </div>
                                    </div>
                                )}
                            </CCardHeader>
                            <CCardBody>
                                {/* Add content here if needed */}
                            </CCardBody>
                        </CCard>
                    ))}
                </CContainer>
            </CCol>
        );
    };

    return (
        <>
            {isLoading ? displayPropagateLoader() :
                (<CContainer>
                    <CRow className="justify-content-center">
                        <CCol md="lg" style={{ justifyContent: 'center', display: 'flex', maxWidth: '80%', marginBottom: '10px' }}>
                            <a style={{ fontSize: '20px' }}>Favorilerim</a>
                        </CCol>
                    </CRow>
                    <CRow className="justify-content-center">
                        <CCol md="lg" style={{ justifyContent: 'center', display: 'flex', maxWidth: '80%', marginBottom: '10px' }}>
                            <CButton
                                style={{ fontSize: '14px', backgroundColor: activeButton === 'yargitay' ? '#6B1A28' : '', color: activeButton === 'yargitay' ? '#EFE8E1' : '' }}
                                onClick={() => changeDocumentTab('yargitay')}
                            >
                                Yargıtay
                            </CButton>
                            <CButton
                                style={{ fontSize: '14px', backgroundColor: activeButton === 'danistay' ? '#6B1A28' : '', color: activeButton === 'danistay' ? '#EFE8E1' : '' }}
                                onClick={() => changeDocumentTab('danistay')}
                            >
                                Danıştay
                            </CButton>
                            <CButton
                                style={{ fontSize: '14px', backgroundColor: activeButton === 'mevzuat' ? '#6B1A28' : '', color: activeButton === 'mevzuat' ? '#EFE8E1' : '' }}
                                onClick={() => changeDocumentTab('mevzuat')}
                            >
                                Mevzuat
                            </CButton>
                        </CCol>
                    </CRow>

                    <CRow className="justify-content-center">
                        {renderSearchResults()}
                    </CRow>
                    {DocumentModal()}
                </CContainer>)}
        </>

    );
};

export default Favorites;
