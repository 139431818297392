import React from "react";
import "./Input.css";

const Input = ({ children, ...props }) => {
  return (

      <input {...props} className="inputLeagle" />

  );
};

export default Input;
