import axios from 'axios';
import { getAuth } from 'firebase/auth';
import config from "../config/config";

const axiosClient = axios.create({
    baseURL: config.apiEndpoint,
});

axiosClient.interceptors.request.use(
    async (config) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const tokenExpirationTime = user.stsTokenManager.expirationTime;
            const fiveMinutesFromNow = new Date().getTime() + 5 * 60 * 1000;

            if (tokenExpirationTime < fiveMinutesFromNow) {
                await user.getIdToken(true);
            }

            const token = await user.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            const auth = getAuth();
            const user = auth.currentUser;

            if (user) {
                try {
                    await user.getIdToken(true);
                    const newToken = await user.getIdToken();
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axiosClient(originalRequest);
                } catch (refreshError) {
                    await auth.signOut();
                }
            }
        }

        return Promise.reject(error);
    }
);

export default axiosClient;