import React from 'react';
import { PropagateLoader } from 'react-spinners';

const Spinner = () => (
    <div className="spinner-container">
        <PropagateLoader />
        <style jsx>{`
      .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    `}</style>
    </div>
);

export default Spinner;