import React, {createContext, useEffect, useState} from 'react';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
} from 'firebase/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);


      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        setToken(idToken);
      } else {
        setToken(null);
      }
    });
  }, []);

  const signUp = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(getAuth(), email, password);
    const idToken = await userCredential.user.getIdToken();
    setToken(idToken);
  };

  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
    const idToken = await userCredential.user.getIdToken();
    setToken(idToken);
  };

  const logout = () => {
    return firebaseSignOut(getAuth()).then(() => {
      setUser(null);
      setToken(null);
    });
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(getAuth(), email);
  };

  return (
      <AuthContext.Provider value={{ user, token, signUp, login, logout, resetPassword, loading }}>
        {children}
      </AuthContext.Provider>
  );
};
