import React from 'react';
import { CInputGroup, CFormInput, CButton } from '@coreui/react';
import Button from './ui/Button/Button';
import Input from './ui/Input/Input';

const SearchInput = ({ query, setQuery, handleSearch, handleKeyDown, isLoading }) => {
    return (
        <div className='d-flex w-100' style={{gap:'4px'}}>
            <Input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Doküman Ara"
                style={{width:'85%'}}
                aria-label="default input example"
                onKeyDown={handleKeyDown}
            />
            <Button style={{width:'15%'}} onClick={() => handleSearch()} color="primary" disabled={isLoading}>
                {isLoading ? "Aranıyor..." : "Ara"}
            </Button>
        </div>
    );
};

export default SearchInput;
