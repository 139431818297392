import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Input from "./ui/Input/Input";


function HukukDairesiSelector({ selectedDairesi, setSelectedDairesi }) {
  const hukukDairesiOptions = Array.from({ length: 18 }, (_, i) => ({
    name: `${i + 1}. Hukuk Dairesi`,
    code: i + 1,
  }));

  return (
    <div className="card">
      <MultiSelect
        style={{ fontFamily: "Futura-Book",width:'100%' }}
        value={selectedDairesi}
        onChange={(e) => setSelectedDairesi(e.value)}
        options={hukukDairesiOptions}
        optionLabel="name"
        placeholder="Hukuk Dairesine göre filtrele"
        display="chip"
      />
    </div>
  );
}

export { HukukDairesiSelector };

function CezaDairesiSelector({ selectedDairesi, setSelectedDairesi }) {
  const cezaDairesiOptions = Array.from({ length: 18 }, (_, i) => ({
    name: `${i + 1}. Ceza Dairesi`,
    code: i + 1,
  }));

  return (
    <div className="card" style={{ marginTop: "5px" }}>
      <MultiSelect
        style={{ fontFamily: "Futura-Book", maxWidth: "100%" }}
        value={selectedDairesi}
        onChange={(e) => setSelectedDairesi(e.value)}
        options={cezaDairesiOptions}
        optionLabel="name"
        placeholder="Ceza dairesine göre filtrele"
        display="chip"
        className="w-full"
      />
    </div>
  );
}

export { CezaDairesiSelector };

const DateFilter = ({
  startDateDay,
  setStartDateDay,
  startDateMonth,
  setStartDateMonth,
  startDateYear,
  setStartDateYear,
  endDateDay,
  setEndDateDay,
  endDateMonth,
  setEndDateMonth,
  endDateYear,
  setEndDateYear,
}) => {
  console.log(
    startDateDay,
    startDateMonth,
    startDateYear,
    endDateDay,
    endDateMonth,
    endDateYear
  );
  const [showStartPicker, setShowStartPicker] = useState(false);
  const [showEndPicker, setShowEndPicker] = useState(false);

  const handleStartDateChange = (selectedDate) => {
    if (selectedDate) {
      setStartDateDay(selectedDate.getDate().toString());
      setStartDateMonth((selectedDate.getMonth() + 1).toString()); // Months are 0-indexed
      setStartDateYear(selectedDate.getFullYear().toString());
      setShowStartPicker(false); // Hide date picker after selection
    }
  };

  const handleEndDateChange = (selectedDate) => {
    if (selectedDate) {
      setEndDateDay(selectedDate.getDate().toString());
      setEndDateMonth((selectedDate.getMonth() + 1).toString());
      setEndDateYear(selectedDate.getFullYear().toString());
      setShowEndPicker(false); // Hide date picker after selection
    }
  };

  const startPickerRef = useRef();
  const endPickerRef = useRef();

  useEffect(() => {
    const rdpRoot = document.querySelector(".form-control");
    const rdpMonths = document.querySelectorAll(".rdp-months");

    const rdpchevron = document.querySelectorAll(".rdp-chevron");

    if (rdpRoot) {
      rdpRoot.style.background = "transparent";
      rdpRoot.style.border = "0px";
      rdpRoot.style.transition = "none";
      rdpMonths.forEach((item) => (item.style.background = "#f3f4f6"));
      rdpMonths.forEach((item) => (item.style.padding = "1rem"));
      rdpMonths.forEach((item) => (item.style.border = "1px solid #4b5563"));
      rdpchevron.forEach((item) => (item.style.fill = "#6B1A28"));
    }
  }, [showStartPicker, showEndPicker]);

  // Hide the picker when clicking outside
  useEffect(() => {
    /*   document.querySelector(".rdpRoot").style.display = "none"; */

    const handleClickOutside = (event) => {
      if (
        startPickerRef.current &&
        !startPickerRef.current.contains(event.target)
      ) {
        setShowStartPicker(false);
      }
      if (
        endPickerRef.current &&
        !endPickerRef.current.contains(event.target)
      ) {
        setShowEndPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className="input-group mb-3 d-flex justify-items-center w-100 mx-auto"
        ref={startPickerRef}
      >
        <span className="input-group-text" style={{ width: "30%" }}>
          Başlangıç Tarihi
        </span>
        <Input
          type="text"
          placeholder="Gün/Ay/Yıl"
          style={{ width: "70%" }}
          value={`${startDateDay}/${startDateMonth}/${startDateYear}`}
          onFocus={() => setShowStartPicker(true)}
          readOnly
        />
        {showStartPicker && (
          <DayPicker
            mode="single"
            onSelect={handleStartDateChange}
            className="form-control"
          />
        )}
      </div>


      <div
        className="input-group mb-3 d-flex justify-items-center w-100 mx-auto"
        ref={endPickerRef}
      >
        <span className="input-group-text" style={{ width: "30%" }}>
          Bitiş Tarihi
        </span>
        <Input
          type="text"
          placeholder="Gün/Ay/Yıl"
          className="form-control"
          style={{ width: "70%" }}
          value={`${endDateDay}/${endDateMonth}/${endDateYear}`}
          onFocus={() => setShowEndPicker(true)}
          readOnly
        />
        {showEndPicker && (
          <DayPicker
            mode="single"
            onSelect={handleEndDateChange}
            className="form-control"
          />
        )}
      </div>
    </div>
  );
};

export { DateFilter };

function MevzuatCategorySelector({ selectedCategory, setSelectedCategory }) {
  const mevzuatOptions = [
    { name: "Kanunlar", code: "kanun" },
    { name: "C. Kararnameleri", code: "cumhur_kararname" },
    { name: "C. ve Bakanlar Kurulu Yönetmelikleri", code: "cumhur_yonetmelik" },
    { name: "C. Kararları", code: "cumhur_karar" },
    { name: "C. Genelgeleri", code: "cumhur_genelge" },
    { name: "KHK", code: "khk" },
    { name: "Tüzükler", code: "tuzuk" },
    {
      name: "Kurum, Kuruluş ve Üniversite Yönetmelikleri",
      code: "kurum_yonetmelik",
    },
    { name: "Tebliğler", code: "teblig" },
  ];

  return (
    <div className="card" >
      <MultiSelect
        style={{ fontFamily: "Futura-Book",width:'100%'}}
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.value)}
        options={mevzuatOptions}
        optionLabel="name"
        placeholder="Kategoriye göre filtrele"
        display="chip"
        className="w-full"

      />
    </div>
  );
}

export { MevzuatCategorySelector };
