import React, { useContext } from "react";
import "./Sidebar.css";
import { cilHamburgerMenu, cilX } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Button from "../ui/Button/Button";
import { NavLinks } from "../../assets/data/nav";
import default_profile_image from "../../assets/images/default_profile_image.png";

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const { user, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {sidebarOpen && (
        <div
          className="sidebar-overlay"
          onClick={() => setSidebarOpen(false)}
        />
      )}
      <nav className={`sidebarLeagle`}>
        {!sidebarOpen && (
          <div
            onClick={() => setSidebarOpen(true)}
            className="burger"
            htmlFor="burger"
          >
            <CIcon icon={cilHamburgerMenu} size="3xl" />
          </div>
        )}
        <div className={`sidebarsubmenu ${sidebarOpen ? "show" : ""}`}>
          {sidebarOpen && (
            <div className="close-btn" onClick={() => setSidebarOpen(false)}>
              <CIcon icon={cilX} color="black" size="3xl" />
            </div>
          )}
          <ul className="nav-menu relative">
            {NavLinks.map((link) => (
              <li>
                <Link to={link.path}>{link.title}</Link>
              </li>
            ))}
          </ul>
          {user ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                bottom: "10px",
                gap: "8px",
                left: 0,
              }}
            >
              <Link
                to={"/profile"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",

                  textDecoration: "none", // Ensures no underline for the link
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    height: "100%",

                    cursor: "pointer",
                  }}
                >
                  <img
                    src={user.photoURL ? user.photoURL : default_profile_image}
                    alt="Profile"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ color: "black" }}>
                    {user.displayName || user.email || "Kullanıcı"}
                  </span>
                </div>
              </Link>
              <Button onClick={handleLogout} style={{ width: "60%" }}>
                Çıkış Yap
              </Button>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                width: "100%",
                bottom: "10px",
                gap: "8px",
                left: 0,
              }}
            >
              <Link to="/login" style={{ width: "100%" }}>
                <Button style={{width:'60%'}}>
                  Giriş Yap
                </Button>
              </Link>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
