import {auth, db} from "../firebaseConfig";
import {arrayUnion, collection, doc, getDoc, setDoc, Timestamp, updateDoc} from "firebase/firestore";

const firebaseClient = {

    async saveSearchToFirestore(query) {
        const user = auth.currentUser;

        if (!user) {
            console.error("No user is authenticated");
            return;
        }

        const userId = user.uid;
        const userSearchesRef = collection(db, `users/${userId}/aiSearches`);
        const searchRef = doc(userSearchesRef);

        const search = {
            query,
            createdAt: Timestamp.fromDate(new Date()),
        };

        try {
            await setDoc(searchRef, search);
        } catch (error) {
            console.error("Error saving search to Firestore: ", error);
        }
    },

    async saveDocumentTalkToFirestore(query, response, sessionID) {
        const user = auth.currentUser;
        if (!user) {
            console.error('No user logged in');
            return;
        }

        const userId = user.uid;
        const userDocumentViewTalksRef = collection(db, `users/${userId}/documentViewTalks`);
        const sessionRef = doc(userDocumentViewTalksRef, sessionID);

        try {
            const docSnap = await getDoc(sessionRef);
            if (docSnap.exists()) {
                await updateDoc(sessionRef, {
                    responses: arrayUnion(
                        {speaker: 'User', message: query},
                        response
                    )
                });
            } else {
                const thread = {
                    responses: [
                        {speaker: 'User', message: query},
                        response
                    ],
                    createdAt: Timestamp.fromDate(new Date()),
                };
                await setDoc(sessionRef, thread);
            }
        } catch (error) {
            console.error('Error in saveDocumentTalkToFirestore:', error);
            throw error;
        }
    },

    async saveConversationToFirestore(query, newResponse, referenceDocuments, thread_id)  {
        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const userId = user.uid;
        const userThreadsRef = collection(db, `users/${userId}/threads`);
        const threadRef = doc(userThreadsRef, thread_id);
        const docSnap = await getDoc(threadRef);

        if (docSnap.exists()) {
            try {
                await updateDoc(threadRef, {
                    responses: arrayUnion({speaker: 'User', message: query}, newResponse)
                });
            } catch (error) {
            }
        } else {
            const thread = {
                query,
                responses: [{speaker: 'User', message: query}, newResponse],
                createdAt: Timestamp.fromDate(new Date()),
                referenceDocuments: referenceDocuments
            };
            try {
                await setDoc(threadRef, thread);
            } catch (error) {
            }
        }
    },

    async fetchThread(threadId){
        const user = auth.currentUser;

        if (!user) {
            return;
        }

        const userId = user.uid;
        const userThreadsRef = collection(db, `users/${userId}/threads`);
        const threadRef = doc(userThreadsRef, threadId);
        return await getDoc(threadRef);
    }

}

export default firebaseClient