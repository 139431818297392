import React from "react";
import { CContainer, CButton } from "@coreui/react";
import {
  HukukDairesiSelector,
  CezaDairesiSelector,
  DateFilter,
} from "../components/Filtering";
import Button from "./ui/Button/Button";
import Button2 from "./ui/Button2/Button2";

const IctihatFilterPanel = ({
  startDateDay,
  setStartDateDay,
  startDateMonth,
  setStartDateMonth,
  startDateYear,
  setStartDateYear,
  endDateDay,
  setEndDateDay,
  endDateMonth,
  setEndDateMonth,
  endDateYear,
  setEndDateYear,
  selectedHukukDairesi,
  setSelectedHukukDairesi,
  selectedCezaDairesi,
  setSelectedCezaDairesi,
  filterResults,
  sortByDate,
  sortByRelevancy,
}) => {
  return (
    <>
      <div
        style={{ gap: "8px", marginBottom: "10px",paddingLeft:'8px',paddingRight:'8px' }}
        className="d-flex justify-content-center align-items-center"
      >
        <Button2 style={{ width: "100%",fontSize:'12px' }} color="primary" onClick={sortByDate}>
          Tarihe Göre Sırala
        </Button2>
        <Button2
style={{ width: "100%",fontSize:'12px' }}
          color="primary"
          onClick={sortByRelevancy}
        >
          İlgiye Göre Sırala
        </Button2>
      </div>

      <DateFilter
        startDateDay={startDateDay}
        setStartDateDay={setStartDateDay}
        startDateMonth={startDateMonth}
        setStartDateMonth={setStartDateMonth}
        startDateYear={startDateYear}
        setStartDateYear={setStartDateYear}
        endDateDay={endDateDay}
        setEndDateDay={setEndDateDay}
        endDateMonth={endDateMonth}
        setEndDateMonth={setEndDateMonth}
        endDateYear={endDateYear}
        setEndDateYear={setEndDateYear}
      />

        <HukukDairesiSelector
          selectedDairesi={selectedHukukDairesi}
          setSelectedDairesi={setSelectedHukukDairesi}
        />
        <CezaDairesiSelector
          selectedDairesi={selectedCezaDairesi}
          setSelectedDairesi={setSelectedCezaDairesi}
        />


      <CContainer style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ margin: "10px" }}
          color="primary"
          onClick={filterResults}
        >
          Filtrele
        </Button>
      </CContainer>
    </>
  );
};

export default IctihatFilterPanel;
