import axios from 'axios';
import React from 'react';
import {auth, db} from '../firebaseConfig';
import {collection, doc, setDoc, updateDoc, Timestamp, arrayUnion, getDoc} from "firebase/firestore";
import config from "../config/config";

export const getScoreColor = (score) => {
    const normalizedScore = score / 4;
    const red = Math.floor(255 * (1 - normalizedScore));
    const green = Math.floor(255 * normalizedScore);
    return `rgb(${red},${green},0)`;
};

export function formatDate(dateString) {
    const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
    return new Date(dateString).toLocaleDateString('en-GB', options);
}

export const parseIctihatBody = (ictihatBody) => {
    return ictihatBody.trim().split('\n').map((line, index) => {
        if (line.includes('"İçtihat Metni"')) {
            const parts = line.split('"İçtihat Metni"');
            return (
                <React.Fragment key={index}>
                    <p>{"İçtihat Metni"}</p>
                    {parts[1] && <p>{parts[1]}</p>}
                </React.Fragment>
            );
        } else {
            return line.trim() !== "" ? <p key={index}>{line}</p> : <br key={index}/>;
        }
    });
};

export const getScoreWidth = (score) => {
    return `${(score / 4) * 100}%`;
};
