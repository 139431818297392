import React, {useEffect, useRef, useState} from "react";
import {CContainer, CRow, CCol, CButton, CCard, CCardHeader, CCardBody, CForm, CFormLabel} from "@coreui/react";
import default_profile_image from "../assets/images/default_profile_image.png";
import Input from "./ui/Input/Input";
import Button2 from "./ui/Button2/Button2";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {auth, db} from "../firebaseConfig";
import { Toast } from "primereact/toast";
import {PropagateLoader} from "react-spinners";


const Profile = () => {
    const toast = useRef(null);
    const [user, setUser] = useState(null);
    const [profileData, setProfileData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        age: "",
        occupation: "",
        organization: "",
    });
    const [isLoading, setIsLoading] = useState(true);

    const showNegativeToast = (message) => {
        if (toast.current) {
            toast.current.show({
                severity: "error",
                summary: "Hatalı!",
                detail: message,
                life: 3000,
            });
        } else {
            console.error("Toast ref is null");
        }
    };

    const showPositiveToast = (message) => {
        if (toast.current) {
            toast.current.show({
                severity: "success",
                summary: "Başarılı!",
                detail: message,
                life: 3000,
            });
        } else {
            console.error("Toast ref is null");
        }
    };

    const fetchUserData = async () => {
        setIsLoading(true);
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUser(currentUser);
            const userDoc = await getDoc(doc(db, "users", currentUser.uid));
            if (userDoc.exists()) {
                setProfileData(userDoc.data());
            }
        }
        setIsLoading(false);
    };

    function displayPropagateLoader() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <PropagateLoader />
            </div>
        );
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user) {
            try {
                await updateDoc(doc(db, "users", user.uid), {
                    name: profileData.name,
                    email: profileData.email,
                    phoneNumber: profileData.phoneNumber,
                    age: profileData.age,
                    occupation: profileData.occupation,
                });
                showPositiveToast("Profiliniz başarıyla güncellendi!");
            } catch (error) {
                showNegativeToast("Profili güncellerken bir hata oluştu!");
            }
        }
    };

    return (
        <>
            {isLoading ? displayPropagateLoader() :
                (<CContainer
                    className="profile"
                    style={{
                        maxWidth: "600px",
                        minWidth: "200px",
                        overflow:'hidden',
                        margin: "auto",
                        textAlign: "center",
                        padding: "20px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                >

                    <CCard>
                        <CCardHeader style={{ color: "#555" }}>Profil</CCardHeader>
                        <CCardBody>
                            <div style={{ marginBottom: "20px" }}>
                                <img
                                    src={user?.photoURL || default_profile_image}
                                    alt="Profile"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                            <CForm onSubmit={handleSubmit}>
                                <CRow className="mb-3">
                                    <CCol md="12">
                                        <p style={{ fontSize: "16px" }}>{profileData.name}</p>
                                    </CCol>
                                </CRow>
                                <div className="row mb-3">
                                    <div class="col-sm text-center">
                                        <CFormLabel htmlFor="email">Email:</CFormLabel>
                                    </div>
                                    <div class="col-sm text-center">
                                        <Input
                                            type="text"
                                            style={{width:'100%'}}
                                            id="email"
                                            name="email"
                                            value={profileData.email}
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div class="col-sm text-center">
                                        <CFormLabel htmlFor="organization">Organizasyon:</CFormLabel>
                                    </div>
                                    <div class="col-sm  text-center">
                                        <Input
                                            type="text"
                                            id="organization"
                                            style={{width:'100%'}}

                                            name="organization"
                                            value={profileData.organization}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div class="col-sm text-center">
                                        <CFormLabel htmlFor="occupation">Rol:</CFormLabel>
                                    </div>
                                    <div class="col-sm text-center">
                                        <Input
                                            type="text"
                                            id="occupation"
                                            name="occupation"
                                            style={{width:'100%'}}

                                            value={profileData.occupation}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div class="col-sm text-center">
                                        <CFormLabel htmlFor="phoneNumber">Telefon Numarası:</CFormLabel>
                                    </div>
                                    <div class="col-sm text-center">

                                        <Input
                                            type="text"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            style={{width:'100%'}}

                                            value={profileData.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <Button2 type="submit" color="primary" style={{ marginTop: "20px",width:'100%' }}>
                                    Güncelle
                                </Button2>
                            </CForm>
                        </CCardBody>
                    </CCard>

                    <Toast ref={toast} />
                </CContainer>
                )}
        </>
  );
};

export default Profile;
