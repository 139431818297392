import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import logo from "../../assets/images/leagle_logo2.png";
import Button from "../ui/Button/Button";
import Sidebar from "./Sidebar";
import { NavLinks } from "../../assets/data/nav";

const Header = () => {
  const { user, logout } = useContext(AuthContext);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleLogout = () => {
    logout();
  };

  return (
    <header>
      <div className="navbar" style={{ padding: "15px 20px" }}>
        <div className="navbar-logo-container">
          <Link to={'/'}>
          <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>

        {/* Repeat this block for other menu items */}
        {NavLinks.map((link, idx) => (
          <div key={idx} className="menu-items">
            <Link to={link.path} className="menu-button">
              {link.title}
            </Link>
          </div>
        ))}

        <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />

        <div className="auth-container">
          {user ? (
            <>
              <span
                className="greeting"
                style={{ marginRight: "10px", color: "black" }}
              >
                Merhaba, {user.displayName || user.email || "Kullanıcı"}
              </span>
              <Button onClick={handleLogout}>Çıkış Yap</Button>
            </>
          ) : (
            <Link to="/login">
              <Button color="primary">Giriş Yap</Button>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
