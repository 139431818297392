import "./Button2.css";

const Button2 = ({ children, ...props }) => {
  return (
    <button id="bottone1" {...props}>
      <span>{children}</span>
    </button>
  );
};

export default Button2;
