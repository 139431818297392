import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header/Header';
import ProtectedRoute from './components/ProtectedRoute';
import DokumanAramaPage from './views/DokumanAramaView';
import MevzuatView from './views/MevzuatView';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import '@coreui/coreui/dist/css/coreui.min.css';
import './styles/scss/style.scss';
import './styles/custom_styles.css';
import ImprovedSearchView from './views/ImprovedSearchView';
import SelectedDocumentView from './views/SelectedDocumentView';
import {CFooter, CLink} from '@coreui/react';
import AssistantView from "./views/AssistantView";
import ProfileView from "./views/ProfileView";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <RoutesApp />
      </Router>
    </AuthProvider>
  );
};

const RoutesApp = () => {
  
  const location = useLocation();
  const showHeader = location.pathname === "/login";

  return (
    <>
      {showHeader && <Header />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Navigate to="/yapay-zeka-ile-arama" />} />
          <Route path="/profile" element={<ProtectedRoute><ProfileView /></ProtectedRoute>} />
          <Route path="/profile/favorites" element={<ProtectedRoute><ProfileView /></ProtectedRoute>} />
          <Route path="/dokuman-arama-page" element={<ProtectedRoute><DokumanAramaPage /></ProtectedRoute>} />
          <Route path="/yapay-zeka-ile-arama" element={<ProtectedRoute><ImprovedSearchView key={Date.now()}/></ProtectedRoute>} />
          {/*<Route path="/asistan" element={<ProtectedRoute><AssistantView/></ProtectedRoute>}/>*/}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/selected-document" element={<ProtectedRoute><SelectedDocumentView /></ProtectedRoute>} />
          <Route path="/mevzuat" element={<ProtectedRoute><MevzuatView /></ProtectedRoute>} />
        </Routes>
      </div>
    </>
  );
};

export default App;
